import { version } from './version';

export const environment = {
  BASE_URL: 'https://dte.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '1',
  schema: 'pbi_dte_demo',
  groupId: '7c8d1f10-a3ee-4d26-8984-d4850e2c8454',
  analiseGeral: 'aa61d9fd-47e6-4cd3-b675-ce12771f7529',
  analiseUsuarios: '549ad4d5-c922-4e9e-a44f-f7c6f602ca92',
  analiseComunicados: '8ac6beb6-f358-4f16-a361-5f25e64176d3',
  analiseProcurador: '0b61cfd2-4435-41b1-8246-37169bcb3cb8',
  analiseEventos: '92ad27bb-2ef8-4239-a71d-43ed60a85c71',
  analiseContribuintes: '8fd9b43e-64bc-48ca-a03f-c887ed477e66'
};
